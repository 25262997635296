<template>
  <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>日志管理</el-breadcrumb-item>
      </el-breadcrumb>
    <div class="content_li">
      <div class="contant flex flex_row">
        <div class="rightContant">
          <el-table
            :data="logList"
            style="width: 100%"
          :header-cell-style="this.utils.getRowClass"
            border
            :row-class-name="tableClassName"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="level" label="日志等级"></el-table-column>
            <el-table-column align="center" prop="title" label="操作"></el-table-column>
            <el-table-column align="center" prop="loginName" label="操作账号"></el-table-column>
            <el-table-column align="center" prop="url" label="访问地址"></el-table-column>
            <!-- <el-table-column align="center" prop="isMobile" label="📱?">
            <template slot-scope="scope">
              {{ scope.row.isMobile ? '是' : '否' }}
            </template>
            </el-table-column>-->
            <el-table-column align="center" prop="ip" label="访问IP"></el-table-column>

            <el-table-column align="center" prop="browser" label="浏览器"></el-table-column>
            <el-table-column align="center" prop="handTime" label="处理时间(ms)">
              <template slot-scope="scope">{{ scope.row.handTime }}ms</template>
            </el-table-column>
            <!-- <el-table-column align="center" prop="exception" label="异常信息"> </el-table-column> -->
            <el-table-column align="center" prop="params" label="操作">
              <template slot-scope="scope">
                <div class="flex flex_ju_center">
                  <div class="tabbtn" @click="paramsDetail(scope.row.params)">参数</div>
                  <div
                    class="tabbtn"
                    v-if="scope.row.level == 'ERROR'"
                    @click="paramsDetail(scope.row.exception)"
                  >查看异常信息</div>
                  <!-- <el-button size="mini" type="primary" @click="paramsDetail(scope.row.params)">查看参数</el-button>
                <el-button
                  size="mini"
                  style="margin-top: 20px;"
                  v-if="scope.row.level == 'ERROR'"
                  type="primary"
                  @click="paramsDetail(scope.row.exception)"
                  >查看异常信息</el-button
                  >-->
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-dialog title :visible.sync="dialogFormVisible">
            <div>{{ value }}</div>
          </el-dialog>
          <div class="page flex flex_end">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="100"
              layout="prev, pager, next, jumper"
              :total="total"
              prev-text="上一页"
              next-text="下一页"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      dialogFormVisible: false,
      currentPage: 1,
      logList: [],
      total: 0,
      value: ""
    };
  },
  mounted() {
    this.getLog();
  },
  methods: {
    tableClassName({ row, rowIndex }) {
      if (row.level === "ERROR") {
        return "warning-row";
      }
      return "";
    },
    getLog() {
      let _that = this;
      let data = {
        page: _that.currentPage,
        pageSize: 10
      };
      _that.$api.log(data).then(res => {
        if (res.data.code == 200) {
          _that.logList = res.data.data.records;
          _that.total = res.data.data.total;
        } else {
          _that.utils.error(res.data.message);
        }
      });
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
      let _that = this;
      _that.currentPage = val;
      _that.getLog();
    },
    paramsDetail(e) {
      this.dialogFormVisible = true;
      this.value = e;
    }
  }
};
</script>
<style lang="less" scoped>
.contant {
  .leftContant {
    width: 20%;
  }
  .rightContant {
    width: 100%;
  }
  .color_font {
    color: #409eff;
    font-size: 14px;
    cursor: pointer;
  }
}
/deep/ .warning-row {
  background-color: #f5f7fa !important;
}
.tabbtn {
  padding: 5px 10px;
  background: #409eff;
  margin-right: 10px;
  color: #fff;
  border-radius: 5px;
}
</style>
